//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { retrieveButter,
         getStateRules,
         getStateRulesFieldDescriptions,
         getElections,
         getUpcomingElections,
         getLastElection,
         getDeadlineElection,
         getDeadlines,
         getDictionary,
         getDictFLJ,
         getDictWP,
         getLang,
         getChosenState,
         getNewVfaDate,
         updateFvapLeosWithButter } from '~/utils/butterUtils'
import { DEFAULT_STATE_VOTING_RULE,
         FAKE_STATES,
         MS_PER_DAY,
         PREFER_NOT_TO_ANSWER,
         STATE_ISO_CODE,
         getVFAParameters } from '~/utils/VFAParameters'

export default {
  name: 'VFA-Volunteers',
  data () {
    return {
      buttonClass: {
        'is-outlined': true,
        'mb4': true,
        button: true
      },
      stateRulesList: [],
    }
  },
  async asyncData ({ store }) {
    await retrieveButter(store, 'pages/vfa-volunteers asyncData ')
  },
  computed: {
    showCodeFragmentMark () { return this.$store.state.showCodeFragmentMark },
    isProductionSite () { return process.env.stage === 'prod' },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    stateVotingRulesFieldDescriptions () {
      return getStateRulesFieldDescriptions (this.butterStateVotingRulesFieldDescriptions[0], this.lang, this.$store.state.showDictionaryKeys)
    },
    statesUpcomingElections () {
      const elections = this.statesElections
      let elup = elections.filter(el=>new Date(el.date).getTime()>=getNewVfaDate().getTime())
      return elup
    },
    statesElections () {
      const elections = this.butterStateElections
      let elec = []
      for (let ii=0; ii<elections.length; ii++) {
        let oneel = this.getElectionActivities (elections[ii], this.lang)
        elec = elec.concat(oneel)
      }
      let elsorted = elec.sort((a,b)=>{return (a.key<b.key)?-1:1})
      return elsorted
    },
    allStatesRules () {
      return this.stateRulesList
    },
    volColumns () {
      return this.volunteerData.tile_columns
    },
    volPage () {
      return this.volunteerData.vol
    },
    volunteerData () {
      return this.getVolunteer(this.butterVolunteerData, this.lang)
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return {"ERROR": "error"}
      }
    },
    butterLeos () {
      return this.$store.state.butterLeos
    },
    butterStateElections () {
      return this.$store.state.butterStateElections.filter(x => !FAKE_STATES.includes(x.stateid.slice(0,2)))
    },
    butterStateVotingRules () {
      return this.$store.state.butterStateVotingRules.filter(x => !FAKE_STATES.includes(x.stateid.slice(0,2)))
    },
    butterStateVotingRulesFieldDescriptions () {
      return this.$store.state.butterStateVotingRulesFieldDescriptions
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    butterVolunteerData () {
      return this.$store.state.butterVolunteerData
    },
    statesSortedLocalized () {
      return STATE_ISO_CODE
        .filter(x => !FAKE_STATES.includes(x))
        .map(x => ({iso: x, name: this.getDictFLJ( `states.${x}`, this.dict)}))
        .sort((a, b) => a.name.localeCompare(b.name))
    },

  },
  methods: {
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
    getVolunteer(volData, lang) {
      /*
         Reformats the response from an API call to ButterCMS to get collection containing volunteer items.

       * tile ids consist of one letter followed by an integer
       * tiles are placed in a grid where...
       * the letter identifies the column; the number identifies the row
       * 
       * note: rows are not aligned to appear at the same vertical position on screen
       */
      let tile_ids = []
      let vol = {
        "pageTitle": "",
        "pageInstructions": "",
        "tiles": {},
      }

      const n_items = volData.length
      let ii = 0
      while (ii<n_items) {
        const tt = volData[ii] // tile ii
        const tile_id = tt.id.toUpperCase()
        tile_ids.push(tile_id)

        vol.pageTitle = tt["page_title_"+lang] ? tt["page_title_"+lang] : vol.pageTitle
        vol.pageInstructions = tt["page_instructions_"+lang] ? tt["page_instructions_"+lang] : vol.pageInstructions

        vol.tiles[tile_id] = {}
        vol.tiles[tile_id].label = tt["tile_label_"+lang]
        vol.tiles[tile_id].instructions = tt["tile_instructions_"+lang]
        vol.tiles[tile_id].links = []

        let jj = 1
        while (true) {
          try {
            /**
             * the links are "indexed" in the Butter collection with two-character
             * string representations of the integers starting at 01
             */
            let linkindex = parseInt(jj)
            if (jj<10) {
              linkindex = '0'+linkindex
            }

            let linklabel = tt["link_label_"+linkindex+"_"+lang].trim()
            if (linklabel) {
              let link = {}
              link.label = linklabel
              link.url = tt["link_url_"+linkindex]
              vol.tiles[tile_id].links.push(link)
            }
          } catch (error) {
            break
          }

          jj++
        }

        ii++
      }

      //construct the matrix of vectors which contain the tile ids for one column
      let tile_columns = {}
      tile_ids.sort()
      for (let k=0; k<tile_ids.length; k++) {
        let tile_id_k = tile_ids[k]
        let letter = tile_id_k.charAt(0)

        if (!Object.keys(tile_columns).includes(letter)) {
          tile_columns[letter] = []
        }
        tile_columns[letter].push(tile_id_k)
      }

      return { tile_columns, vol }
    },
    getElectionActivities (election, lang) {
      let el = []

      // number == the number of times the activity appears in the state voting rules Butter collection schema
      let activities = [ {name:"registration", number:4}, {name:"ballotrequest", number:6}, {name:"ballotreturn", number:4}]

      /* election day*/
      
      let one = {}
      one.iso = election.stateid.slice(0,2)
      one.statename = election.stateid.slice(3)
      one.key = election.date.slice(0,10)+"zz"+election.stateid // "zz" to force all elections to appear together at the end of the day
      one.activity = "electionday"

      one.electionname      = election.electionname
      one.electiontype      = election.electiontype
      one.votertype         = ""
      one.voterisregistered = ""
      one.rule              = ""
      one.date              = election.date
      one.note              = ""
      
      el.push(one)

      /* non-election day activities */
      
      for (let mm=0; mm<activities.length; mm++) {
        let activityName  = activities[mm].name
        let activityItems = activities[mm].number
        
        for (let ii=1; ii<=activityItems; ii++) {
          let dd = election[activityName+"_"+ii.toString()+"_date"]
          if (dd==="") {
            continue
          }

          let one = {}
          one.iso = election.stateid.slice(0,2)
          one.statename = election.stateid.slice(3)
          one.key = dd.slice(0,10)+"aa"+election.stateid  // "aa" to force non-elections to appear before elections
          one.activity = activityName

          one.votertype         = election[activityName+"_"+ii.toString()+"_votertype"]
          one.voterisregistered = election[activityName+"_"+ii.toString()+"_voterisregistered"]
          one.rule              = election[activityName+"_"+ii.toString()+"_rule"]
          one.date              = election[activityName+"_"+ii.toString()+"_date"]
          one.note              = election[activityName+"_"+ii.toString()+"_note_"+lang]

          // make friendly text for the PDF document

          one.friendlyactivity = ({ registration: this.dict.D21, ballotrequest: this.dict.D14, ballotreturn: this.dict.D15})[one.activity]

          /*
            2023-12-03 Heidi Burch
            revise appearance of a date-time for transmission options.
            remove "T00:00:00"
            replace the "T" with a space
            remove the seconds from a time
          */
          let dt = one.date.split("T")
          dt[1] = dt[1].slice(0,5)
          if (dt[1]==="00:00") {
            dt[1]=""
          }
          one.friendlyrule = this.getDictFLJ("election."+one.rule, this.dict)+" "+dt.join(" ")

          /*
            2023-12-03 Heidi Burch
            revise appearance of the line for transmission options.
            force a new line prior to the phrase ": Received by", ": Sent by", ": Postmarked by", or ": Signed by"
            indicate this by inserting in the text "<br>", the HTML tag for a new line
          */
          one.friendlyrule = one.friendlyrule.replaceAll(": Received by", ":<br>Received by")
          one.friendlyrule = one.friendlyrule.replaceAll(": Sent by", ":<br>: Sent by")
          one.friendlyrule = one.friendlyrule.replaceAll(": Postmarked by", ":<br>Postmarked by")
          one.friendlyrule = one.friendlyrule.replaceAll(": Signed by", ":<br>Signed by")

          el.push(one)
        }
      }

      return el
    },
    removeHTML(bigLine) {
      /*
        <a ...>   <== retain the href text; remove rel="follow noopener", /target="_blank", and class="is-vfa-link"
      */
      return bigLine.replace(/&nbsp;/gi, "")
                    .replace(/<b(.*?)>/gi, "").replace(/<\/b>/gi, "")
                    .replace(/<p(.*?)>/gi, "").replace(/<\/p>/gi, "<br>")
                    .replace(/<div(.*?)>/gi, "").replace(/<\/div>/gi, "")
                    .replace(/<span(.*?)>/gi, "").replace(/<\/span>/gi, "")
                    .replace(/<strong(.*?)>/gi, "").replace(/<\/strong>/gi, "")
                    .replace(/<br(.*?)>$/gi, "")
                    .replace(/rel="follow noopener"/gi, "")
                    .replace(/target="_blank"/gi, "")
                    .replace(/class="is-vfa-link"/gi, "")
    },
    makeStateRulesList () {
      let rulesArray=[]
      const subset = this.butterStateVotingRules.filter(x => STATE_ISO_CODE.includes(x.stateid.slice(0,2)))
      for (let ii=0; ii<subset.length; ii++) {
        let rule={}

        rule.stateid = subset[ii].stateid
        rule.iso = subset[ii].stateid.slice(0,2)
        rule.statename = subset[ii].stateid.slice(3)
        rule.allowsneverresided = subset[ii].allowsneverresided

        let ids = subset[ii].id.split("-or-")
        let ruleids = []
        for (let jj=0; jj<ids.length; jj++) {
          ruleids.push(getDictFLJ(`request.id.${ids[jj]}`, this.dict))
        }
        rule.id = ruleids.join(" or ")

        rule.id_when_recballot_email = subset[ii].id_when_recballot_email
        rule.id_when_recballot_email_tooltip = subset[ii]["id_when_recballot_email_tooltip_"+this.lang]
        rule.partyprimary = subset[ii].partyprimary
        rule.alternate_name_democratic = subset[ii].alternate_name_democratic
        rule.alternate_name_republican = subset[ii].alternate_name_republican
        rule.deliverymethodregistration = getDictFLJ(`generic.${subset[ii].deliverymethodregistration}`, this.dict)
        rule.deliverymethodregistrationnote = subset[ii]["deliverymethodregistrationnote_"+this.lang]
        rule.deliverymethodballotrequest = getDictFLJ(`generic.${subset[ii].deliverymethodballotrequest}`, this.dict)
        rule.deliverymethodballotrequestnote = subset[ii]["deliverymethodballotrequestnote_"+this.lang]
        rule.deliverymethodballotreturn = getDictFLJ(`generic.${subset[ii].deliverymethodballotreturn}`, this.dict)
        rule.deliverymethodballotreturnnote = subset[ii]["deliverymethodballotreturnnote_"+this.lang]
        rule.ballotreceiptoptions = getDictFLJ(`generic.${subset[ii].ballotreceiptoptions}`, this.dict)
        rule.allowsscannedsignature = subset[ii].allowsscannedsignature
        rule.fpcasubmitoptionsregister = getDictFLJ(`generic.${subset[ii].fpcasubmitoptionsregister}`, this.dict)
        rule.fpcasubmitoptionsrequest = getDictFLJ(`generic.${subset[ii].fpcasubmitoptionsrequest}`, this.dict)
        rule.canregisterwithfwab = subset[ii].canregisterwithfwab
        rule.fwabsubmitoptions = getDictFLJ(`generic.${subset[ii].fwabsubmitoptions}`, this.dict)
        rule.amiregistered = subset[ii].amiregistered
        rule.whereismyballot = subset[ii].whereismyballot
        rule.trackyourballot = subset[ii].trackyourballot
        rule.sampleballot = subset[ii].sampleballot
        rule.uocavavoters = subset[ii].uocavavoters
        rule.uocavavoters2 = subset[ii].uocavavoters2
        rule.findmypollingplace = subset[ii].findmypollingplace
        rule.militaryseparate = subset[ii].militaryseparate
        rule.votertypesused = subset[ii].votertypesused
        rule.usesvoterisregistered = subset[ii].usesvoterisregistered
        rule.attachmentisrequired = subset[ii].attachmentisrequired
        rule.body = subset[ii]["body_"+this.lang]
        rule.body2 = subset[ii]["body2_"+this.lang]
        rule.sr1label = subset[ii]["sr1label_"+this.lang]
        rule.sr1instructions = subset[ii]["sr1instructions_"+this.lang]
        rule.sr1tooltip = subset[ii]["sr1tooltip_"+this.lang]
        rule.sr2label = subset[ii]["sr2label_"+this.lang]
        rule.sr2instructions = subset[ii]["sr2instructions_"+this.lang]
        rule.sr2tooltip = subset[ii]["sr2tooltip_"+this.lang]
        rule.suppress_si1default = subset[ii].suppress_si1default
        rule.si1body = subset[ii]["si1body_"+this.lang]
        rule.suppress_si2default = subset[ii].suppress_si2default
        rule.si2body = subset[ii]["si2body_"+this.lang]
        rule.suppress_si3default = subset[ii].suppress_si3default
        rule.si3body = subset[ii]["si3body_"+this.lang]
        rule.suppress_si4default = subset[ii].suppress_si4default
        rule.si4body = subset[ii]["si4body_"+this.lang]
        rule.si5body = subset[ii]["si5body_"+this.lang]
        rule.issues1_label = subset[ii]["issues1_label_"+this.lang]
        rule.issues1 = subset[ii]["issues1_"+this.lang]
        rule.specialinput1_isenabled = subset[ii].specialinput1_isenabled
        rule.specialinput1_attachment_is_required = subset[ii].specialinput1_attachment_is_required
        rule.specialinput1_is_always_triggered = subset[ii].specialinput1_is_always_triggered
        rule.specialinput1_registration_trigger = subset[ii].specialinput1_registration_trigger
        rule.specialinput1_abroad_trigger = subset[ii].specialinput1_abroad_trigger
        rule.specialinput1_party_trigger = subset[ii].specialinput1_party_trigger
        rule.specialinput1_identification_trigger = subset[ii].specialinput1_identification_trigger
        rule.specialinput1_label = subset[ii]["specialinput1_label_"+this.lang]
        rule.specialinput1_tooltip = subset[ii]["specialinput1_tooltip_"+this.lang]
        rule.specialinput1_instructions = subset[ii]["specialinput1_instructions_"+this.lang]
        rule.specialinput1_voter_reply_label = subset[ii]["specialinput1_voter_reply_label_"+this.lang]
        rule.specialinput1_voter_reply = subset[ii]["specialinput1_voter_reply_"+this.lang]
        rule.specialinput2_isenabled = subset[ii].specialinput2_isenabled
        rule.specialinput2_attachment_is_required = subset[ii].specialinput2_attachment_is_required
        rule.specialinput2_is_always_triggered = subset[ii].specialinput2_is_always_triggered
        rule.specialinput2_registration_trigger = subset[ii].specialinput2_registration_trigger
        rule.specialinput2_abroad_trigger = subset[ii].specialinput2_abroad_trigger
        rule.specialinput2_party_trigger = subset[ii].specialinput2_party_trigger
        rule.specialinput2_identification_trigger = subset[ii].specialinput2_identification_trigger
        rule.specialinput2_label = subset[ii]["specialinput2_label_"+this.lang]
        rule.specialinput2_tooltip = subset[ii]["specialinput2_tooltip_"+this.lang]
        rule.specialinput2_instructions = subset[ii]["specialinput2_instructions_"+this.lang]
        rule.specialinput2_voter_reply_label = subset[ii]["specialinput2_voter_reply_label_"+this.lang]
        rule.specialinput2_voter_reply = subset[ii]["specialinput2_voter_reply_"+this.lang]
        rule.specialinput3_isenabled = subset[ii].specialinput3_isenabled
        rule.specialinput3_attachment_is_required = subset[ii].specialinput3_attachment_is_required
        rule.specialinput3_is_always_triggered = subset[ii].specialinput3_is_always_triggered
        rule.specialinput3_registration_trigger = subset[ii].specialinput3_registration_trigger
        rule.specialinput3_abroad_trigger = subset[ii].specialinput3_abroad_trigger
        rule.specialinput3_party_trigger = subset[ii].specialinput3_party_trigger
        rule.specialinput3_identification_trigger = subset[ii].specialinput3_identification_trigger
        rule.specialinput3_label = subset[ii]["specialinput3_label_"+this.lang]
        rule.specialinput3_tooltip = subset[ii]["specialinput3_tooltip_"+this.lang]
        rule.specialinput3_instructions = subset[ii]["specialinput3_instructions_"+this.lang]
        rule.specialinput3_voter_reply_label = subset[ii]["specialinput3_voter_reply_label_"+this.lang]
        rule.specialinput3_voter_reply = subset[ii]["specialinput3_voter_reply_"+this.lang]
        rule.dashboardspecial = subset[ii]["dashboardspecial_"+this.lang]
        rule.dashboardspecialdeadline = subset[ii]["dashboardspecialdeadline_"+this.lang]
        rule.dashboardspecialnextstep = subset[ii]["dashboardspecialnextstep_"+this.lang]
        rule.fpcasubmitnotes_label = subset[ii]["fpcasubmitnotes_label_"+this.lang]
        rule.fpcasubmitnotes_body = subset[ii]["fpcasubmitnotes_body_"+this.lang]
        rule.overseas_status_uncertain_return = subset[ii]["overseas_status_uncertain_return_"+this.lang]
        rule.overseas_status_intend_to_return = subset[ii]["overseas_status_intend_to_return_"+this.lang]
        rule.overseas_status_never_resided_in_us = subset[ii]["overseas_status_never_resided_in_us_"+this.lang]
        rule.overseas_status_military_active_duty = subset[ii]["overseas_status_military_active_duty_"+this.lang]
        rule.overseas_status_military_spouse = subset[ii]["overseas_status_military_spouse_"+this.lang]
        rule.unregistered_voter_submit_options = subset[ii].unregistered_voter_submit_options
        rule.gender_is_required = subset[ii].gender_is_required
        rule.gender_options = subset[ii].gender_options
        rule.partyinformation = subset[ii]["partyinformation_"+this.lang]

        /*
          1. ensuring all the values are strings or a space because the pdf writer library requires non-empty strings
          2. remove HTML from the strings because we have not setup HTML to render on the PDF file; there are some fields
             that will never have an HTML, but I don't think it is worthwhile to cut them out of the "removeHTML" loop
        */
        let rulekeys = Object.keys(rule)
        for (let jj=0; jj<rulekeys.length; jj++) {
          let a = rule[rulekeys[jj]].toString()
          a = a ? a : " "
          if (a !== " ") {
            a = this.removeHTML(a)
          }
          rule[rulekeys[jj]] = a
        }

        rulesArray.push(rule)
      }
      this.stateRulesList = rulesArray
    },
  },
  created () {
    if (process.env.stage==='prod') {
      this.$router.push(this.localePath({ name: 'index' }))
    }
  },
  mounted () {
    this.makeStateRulesList()
  },
  watch: {
    lang () {
      this.makeStateRulesList()
    },
  },
}
